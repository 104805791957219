function initialState() {
  return {
    inlineBanner: true,
    appNavigationBanner: false
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setAppNavigationBanner(state, value) {
      state.appNavigationBanner = value;
    },
    setInlineBanner(state, value) {
      state.inlineBanner = value;
    }
  },
  actions: {}
};
export default module;
