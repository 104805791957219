<template>
  <v-app>
    <loading-dialog></loading-dialog>
    <app-navigation style="height: 64px"></app-navigation>
    <app-menu style="margin-top: 64px"></app-menu>
    <v-container fluid>
      <v-main>
        <router-view></router-view>
        <changed-user-dialog></changed-user-dialog>
        <production-environment-notice></production-environment-notice>
      </v-main>
    </v-container>
    <app-version></app-version>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/navigation/AppNavigation.vue";
import { removeUnnecessaryCookies } from "@/utils/column-configurator.js";
import AppMenu from "@/views/AppMenu.vue";
import { mapMutations } from "vuex";
import LoadingDialog from "@/components/dialogs/LoadingDialog.vue";
import ChangedUserDialog from "@/components/dialogs/ChangedUserDialog.vue";
import AppVersion from "@/views/AppVersion.vue";
import ProductionEnvironmentNotice from "@/components/dialogs/ProductionEnvironmentNotice.vue";
import amplitude from 'amplitude-js'

export default {
  name: "App",
  components: {
    AppNavigation,
    AppMenu,
    LoadingDialog,
    ChangedUserDialog,
    ProductionEnvironmentNotice,
    AppVersion,
  },
  methods: {
    ...mapMutations("auth", ["setUserChanged"]),
  },
  async mounted() {
    removeUnnecessaryCookies();
    this.setUserChanged(false);
    if(performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      amplitude.getInstance().init("4ab8acd24bd2b9e8772ce48b2391715e", this.$cookies.get("username"));
    }
  },
};
</script>

<style>
.scrollable-table__medium .v-data-table__wrapper {
  max-height: 25.7rem;
}
.scrollable-table__large .v-data-table__wrapper {
  max-height: 47.9rem;
}
.v-data-table__wrapper > table > tbody > tr > td {
  height: 52px !important;
}
.right-corner {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.exit-icon:hover {
  color: red;
}
.info-icon:hover {
  color: teal;
  transform: scale(1.5);
}
.edit-icon:hover {
  color: #009688;
  transform: scale(1.5);
}
.delete-icon:hover {
  color: var(--v-error-base);
  transform: scale(1.5);
}
.link-text {
  color: rgb(0, 64, 255);
  text-decoration: underline;
  cursor: pointer;
}
.no-wrap {
  white-space: nowrap;
}
.number-effect {
  transition-property: all;
  transition-delay: 0.7s;
  transition-duration: 0.5s;
  animation: bigger-to-smaller 0.5s alternate;
}
.number-effect_space {
  min-width: 22em;
}
.table-header_align.theme--light.v-data-table
  .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  vertical-align: top;
}
.btn-letter_transform {
  text-transform: none;
}
.zoom-in-animation {
  transition: transform 0.2s;
}
.zoom-in-animation:hover {
  transform: scale(1.5);
}
.after-disabled::after {
  display: none !important;
}
@keyframes bigger-to-smaller {
  0% {
    font-size: 17px;
    font-weight: 500;
    color: red;
  }
  100% {
    font-size: 15px;
  }
}
</style>
