<template>
  <v-app-bar app dark>
    <v-btn v-if="!isLoginScreen" @click="toggleMenu()" class="mr-2">
      <v-icon large :color="mainMenuActive ? colorTheme.icon : ''">
        mdi-menu
      </v-icon>
    </v-btn>
    <router-link @click.native="reloadHomePage" to="/" tabindex="-1">
      <SmartXLogo/>
    </router-link>
    <v-toolbar-title> Billing Entity and Fee Management </v-toolbar-title>
    <v-spacer></v-spacer>
    <transition name="slide-fade">
      <div class="welcome-message" v-show="showWelcomeMessage">
        <span :style="{ color: 'rgba(255, 255, 255, 0.4)' }">Welcome, </span>
        <span>
          <b>{{ username }}</b>
        </span>
      </div>
    </transition>
    <v-icon v-if="showWarningIcon">
      mdi-alert
    </v-icon>
    <v-toolbar-title v-if="!this.appNavigationBanner" class="ml-2 env-info">
      {{ environmentInfoText }}
    </v-toolbar-title>
    <v-toolbar-title v-else class="ml-2 banner-font">
      {{ environmentInfoText }}
    </v-toolbar-title>
    <v-btn v-show="!isLoginScreen" @click="openDownloadPortal" icon dark>
      <v-icon>mdi-file</v-icon>
    </v-btn>
    <v-menu
      :disabled="isLoginScreen"
      v-model="menu"
      eager
      offset-y
      open-on-hover
      :close-delay="1000"
      :value="!isLoginScreen"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!isLoginScreen" icon dark v-bind="attrs" v-on="on">
          <v-icon large>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list min-width="200">
        <v-list-item
          v-for="(item, index) in menuList"
          :key="index"
          @click="item.action"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SmartXLogo from "./SmartXLogo.vue";
import { isProduction } from "@/config/organization-config.js";

export default {
  components: {
    SmartXLogo,
  },
  data: () => ({
    menu: false,
    mainMenu: false,
    activeButton: false,
    showWelcomeMessage: false,
  }),
  computed: {
    ...mapState("appNavigation", ["mainMenuActive", "currentApiEnvironment"]),
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("auth", ["user"]),
    ...mapState("hierarchyMode", ["hierarchyTag", "hierarchyTags"]),
    ...mapState("backgroundMessages", ["appNavigationBanner"]),
    username() {
      if (this.user) {
        return this.user.username;
      } else {
        return "";
      }
    },
    showWarningIcon() {
      return isProduction(this.currentApiEnvironment);
    },
    environmentInfoText() {
      if (!this.currentApiEnvironment) {
        return "";
      }
      if (isProduction(this.currentApiEnvironment)) {
        return "Production Environment";
      } else {
        return this.currentApiEnvironment + " Environment";
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    isLoginScreen() {
      if (
        this.currentRouteName === "Authentication" ||
        this.currentRouteName === "ForgotPassword"
      ) {
        return true;
      } else {
        return false;
      }
    },
    menuList() {
      let menu = [
        {
          title: "Profile",
          action: () => this.openProfile(),
        },
        {
          title: "Change password",
          action: () => this.changePassword(),
        },
        {
          title: "Sign out",
          action: () => this.signOut(),
        },
      ];
      return menu;
    },
  },
  watch: {
    isLoginScreen() {
      if (!this.isLoginScreen) {
        this.showWelcomeMessage = true;
        setTimeout(() => {
          this.showWelcomeMessage = false;
        }, 4000);
      }
    },
  },
  methods: {
    ...mapMutations("appNavigation", ["setMainMenuActive"]),
    ...mapActions("auth", ["signOut"]),
    ...mapActions("entityActions", ["setTab"]),
    toggleMenu() {
      this.setMainMenuActive(!this.mainMenuActive);
    },
    openProfile() {
      this.$router.push({ name: "Profile" });
    },
    changePassword() {
      this.$router.push({ name: "ProfileChangePassword" });
    },
    openDownloadPortal() {
      this.$router.push({ name: "DownloadPortal" });
    },
    reloadHomePage() {
      this.$router.push({
        name: "HierarchyTree",
        params: {
          hierarchy: this.hierarchyTag.address,
          id: "root"
        }
      });
    },
  },
};
</script>

<style scoped>
.banner-font {
  font-size: 12px;
}
.welcome-message {
  margin-right: 1rem;
}

.slide-fade-enter-active {
  transition: opacity 0.5s ease;
}
.slide-fade-leave-active {
  transition: opacity 0.2s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.env-info {
  font-size: 14pt;
}
</style>
