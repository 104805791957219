import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import addUpdateAccountsModule from "./add-update-accounts.js";
import modifyEntityGroup from "./add-update-group.js";
import apiEndpointModule from "./api-endpoint.js";
import appNavigationModule from "./app-navigation.js";
import authModule from "./auth.js";
import endpointsModule from "./endpoints.js";
import exclusionsModule from "./exclusions.js";
import exclusionSearchModule from "./modules/exclusions/exclusion-search.js";
import exportsModule from "./exports.js";
import loadingModule from "./loading.js";
import searchEntitiesModule from "./search-entities.js";
import userConfigModule from "./user-config.js";
import pageTitleModule from "./page-title.js";

import accountStatusModule from "./modules/account-statuses/account-status.js";
import fullStatusHistoryModule from "./modules/account-statuses/full-status-history.js";
import invoiceScreenModule from "./modules/account-statuses/invoice-screen.js";
import payoutScreenModule from "./modules/account-statuses/payout-screen.js";

import modifyEntityModule from "./modules/add-update-entity/add-update-entity.js";
import entityAttributesModule from "./modules/add-update-entity/entity-attributes.js";

import browseEntitiesModule from "./modules/browse-entities/browse-entities.js";
import databaseSearchModule from "./modules/browse-entities/database-search.js";
import directoryNavigationModule from "./modules/browse-entities/directory-navigation.js";
import entityActionsModule from "./modules/browse-entities/entity-actions.js";
import hierarchyModeModule from "./modules/browse-entities/hierarchy-mode.js";
import hierarchyTreeModule from "./modules/browse-entities/hierarchy-tree.js";

import feeResultsModule from "./modules/fee-results/fee-results.js";
import feeResultsAccountsModule from "./modules/fee-results/fee-results-accounts.js";
import feeResultsRecordsModule from "./modules/fee-results/fee-results-records.js";

import feeScheduleModule from "./modules/fee-schedules/fee-schedule.js";
import modifyScheduleModule from "./modules/fee-schedules/add-update-schedule.js";
import feeTiersModule from "./modules/fee-schedules/fee-tiers.js";
import pickDealEntityModule from "./modules/fee-schedules/pick-deal-entity.js";

import inceptionBillingModule from "./modules/inceptions/inception-billing.js";
import inceptionAccountsModule from "./modules/inceptions/inception-accounts.js";
import inceptionGroupPickerModule from "./modules/inceptions/inception-group-picker.js";
import inceptionInAdvanceAccountsModule from "./modules/inceptions/inception-in-advance-accounts.js";
import inceptionRecordsModule from "./modules/inceptions/inception-records.js";

import terminationBillingModule from "./modules/terminations/termination-billing.js";
import terminationAccountsModule from "./modules/terminations/termination-accounts.js";
import terminationRecordsModule from "./modules/terminations/termination-records.js";

import accountsBillingModule from "./modules/accounts-billing/accounts-billing.js";
import billingRecordsCalculationsModule from "./modules/billing-records/billing-records-calculations.js";
import reviewFeesSchedulesModule from "./modules/billing-records/review-fees-schedules.js";
import reviewFeesHeadersModule from "./modules/billing-records/review-fees-headers.js";
import adjustmentsModule from "./modules/billing-records/adjustments.js";

import addAccountGroupModule from "./add-account-group.js";

import dialogsModule from "./dialogs.js";
import filtersModule from "./filters.js";

import prepopulatedDataModule from "./prepopulated-data.js";
import columnConfiguratorModule from "./modules/user-settings/column-configurator.js";

import backgroundMessagesModule from "./background-messages.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    addUpdateAccounts: addUpdateAccountsModule,
    modifyEntityGroup: modifyEntityGroup,
    apiEndpoint: apiEndpointModule,
    appNavigation: appNavigationModule,
    auth: authModule,
    endpoints: endpointsModule,
    exclusions: exclusionsModule,
    exclusionSearch: exclusionSearchModule,
    exports: exportsModule,
    loading: loadingModule,
    searchEntities: searchEntitiesModule,
    userConfig: userConfigModule,
    pageTitle: pageTitleModule,

    accountStatus: accountStatusModule,
    fullStatusHistory: fullStatusHistoryModule,
    invoiceScreen: invoiceScreenModule,
    payoutScreen: payoutScreenModule,

    modifyEntity: modifyEntityModule,
    entityAttributes: entityAttributesModule,

    browseEntities: browseEntitiesModule,
    databaseSearch: databaseSearchModule,
    directoryNavigation: directoryNavigationModule,
    entityActions: entityActionsModule,
    hierarchyMode: hierarchyModeModule,
    hierarchyTree: hierarchyTreeModule,

    feeResults: feeResultsModule,
    feeResultsAccounts: feeResultsAccountsModule,
    feeResultsRecords: feeResultsRecordsModule,

    feeSchedule: feeScheduleModule,
    modifySchedule: modifyScheduleModule,
    feeTiers: feeTiersModule,
    pickDealEntity: pickDealEntityModule,

    inceptions: inceptionBillingModule,
    inceptionAccounts: inceptionAccountsModule,
    inceptionGroupPicker: inceptionGroupPickerModule,
    inceptionInAdvanceAccounts: inceptionInAdvanceAccountsModule,
    inceptionRecords: inceptionRecordsModule,

    terminations: terminationBillingModule,
    terminationAccounts: terminationAccountsModule,
    terminationRecords: terminationRecordsModule,

    accountsBilling: accountsBillingModule,
    billingRecordsCalculations: billingRecordsCalculationsModule,
    reviewFeesSchedules: reviewFeesSchedulesModule,
    reviewFeesHeaders: reviewFeesHeadersModule,
    adjustments: adjustmentsModule,

    addAccountGroup: addAccountGroupModule,

    dialogs: dialogsModule,
    filters: filtersModule,

    prepopulatedData: prepopulatedDataModule,
    columnConfigurator: columnConfiguratorModule,

    backgroundMessages: backgroundMessagesModule,
  },
  plugins: [
    createPersistedState({
      key: "vuexSessionState",
      storage: window.sessionStorage,
      paths: [
        "modifyEntityGroup",
        "auth",
        "endpoints",
        "exclusions",
        "exports",

        //account-statuses modules
        "accountStatus",
        "fullStatusHistory",
        "invoiceScreen",
        "payoutScreen",

        //add-update-entity modules
        "modifyEntity",
        "entityAttributes",

        //browse-entities modules
        "browseEntities",
        "directoryNavigation",
        "entityActions",
        "hierarchyMode",
        "hierarchyTree",

        //fee-results modules
        "feeResultsAccounts",
        "feeResultsRecords",

        //fee-schedules modules
        "feeSchedule",
        "feeTiers",

        "billingRecordsCalculations",

        "prepopulatedData",
        "filters",
        "columnConfigurator",

        //modules not kept in storage
        //"addUpdateAccounts",
        //"apiEndpoint",
        //"appNavigation",
        //"loading",
        //"searchEntities",
        //"pickDealEntity",
        //"exclusionSearchModule",
        //"userConfig",
        //"databaseSearch",
        //"feeResults",
        //"modifySchedule",
        //"inceptions",
        //"inceptionAccounts",
        //"inceptionInAdvanceAccounts",
        //"inceptionGroupPicker",
        //"inceptionRecords",
        //"terminations",
        //"terminationAccounts",
        //"terminationRecords",
        //"accountsBilling",
        //"dialogs",
        //"reviewFeesHeaders",
        //"adjustments",
        //"pageTitle",
        //"reviewFeesSchedules",
      ]
    }),
    // Local storage state
    /*createPersistedState({
      key: "vuexLocalState",
      storage: window.localStorage,
      paths: ["auth"]
    })*/
  ]
});
